import React, { useState } from "react";
import { useContext } from "react";
import { UserInfo } from "../models/UserInfo";

type Auth = {
  isLoggedIn: boolean;
  onLogOut: () => void;
  onLogIn: (userInfo: UserInfo) => void;
};

const AuthContext = React.createContext<Auth>({} as Auth);

export const AuthContextProvider: React.FC = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const loginHandler = () => {
    console.log("loginHandler",isLoggedIn);
    setIsLoggedIn(true);
    console.log("loginHandler",isLoggedIn);
  };

  const logoutHandler = () => {
    console.log("logoutHandler",isLoggedIn);
    setIsLoggedIn(false);
    console.log("logoutHandler",isLoggedIn);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        onLogOut: logoutHandler,
        onLogIn: loginHandler,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;
