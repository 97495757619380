import React from "react";
import Card from "../../UI/Card/Card";

const Home = () => {
  return (
    <Card>
      <h1>Welcome back!</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio
        facere asperiores, fugiat ea laboriosam accusantium tempora velit
        aspernatur dolor fugit?
      </p>
    </Card>
  );
};

export default Home;
