import React, { SyntheticEvent } from "react";
import { StyledButton } from "./Button.styles";

interface Props {
  type?: "button" | "reset" | "submit";
  onClick?: (e: SyntheticEvent) => void;
  disabled?: boolean;
  children: React.ReactNode;
}
const Button = ({ disabled, onClick, type, children }: Props) => {
  return (
    <StyledButton type={type || "button"} onClick={onClick} disabled={disabled}>
      {children}
    </StyledButton>
  );
};

export default Button;
