import React from "react";
import AuthContext from "../../store/auth-context";

interface Props {
  className?: string;
}
const Navigation = ({ className }: Props) => {
  return (
    <AuthContext.Consumer>
      {(ctx) => {
        return (
          <nav className={className}>
            <ul>
              {ctx!.isLoggedIn && (
                <>
                  <li>
                    <a href="/">Users</a>
                  </li>
                  <li>
                    <a href="/">Admin</a>
                  </li>
                  <li>
                    <button onClick={ctx!.onLogOut}>Logout</button>
                  </li>
                </>
              )}
            </ul>
          </nav>
        );
      }}
    </AuthContext.Consumer>
  );
};

export default Navigation;
