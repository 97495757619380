import styled from "styled-components";
import Login from "./Login";

export const StyledLogin = styled(Login)`
  .login {
    width: 90%;
    max-width: 40rem;
    margin: 2rem auto;
    padding: 2rem;
  }

  .actions {
    text-align: center;
  }
`;
