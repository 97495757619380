import React, { useRef, useImperativeHandle } from "react";

interface Props {
  isValid: boolean;
  id: string;
  value: string;
  type: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}
const Input = React.forwardRef(
  (
    { id, isValid, value, name, onChange, type, className }: Props,
    ref: any
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const activate = () => {
      inputRef.current?.focus();
    };

    useImperativeHandle(ref, () => {
      return {
        focus: activate,
      };
    });

    return (
      <div className={`${className}`}>
        <div className="control">
          <label htmlFor="email">Email</label>
          <input
            ref={inputRef}
            type={type}
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            className={isValid ? "" : "invalid"}
            autoComplete={"off"}
          />
        </div>
      </div>
    );
  }
);

export default Input;
