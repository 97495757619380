import React from "react";
import styled from "styled-components";

const StyledCard = styled.div`
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
  margin: 20px 20px;
  padding: 20px 20px;
`;

type Props = {
  children?: React.ReactNode;
  className?: string;
};
const Card: React.FC<Props> = ({ children, className }) => {
  return <StyledCard className={className}>{children}</StyledCard>;
};

export default Card;
