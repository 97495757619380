import React from "react";
import MainHeader from "./Layout/MainHeader/MainHeader";
import Home from "./Pages/Home/Home";
import { StyledLogin } from "./Pages/Login/Login.styles";
import { useAuth } from "./store/auth-context";

function App() {
  const { isLoggedIn } = useAuth();
  console.log(isLoggedIn);
  return (
    <>
      <MainHeader />
      <main>{isLoggedIn ? <Home /> : <StyledLogin />}</main>
    </>
  );
}

export default App;
