import { StyledNav } from "../Navigation/Navigation.styles";
import classes from "./MainHeader.module.css";

const MainHeader = () => {
  return (
    <header className={classes["main-header"]}>
      <h1>Main Header</h1>
      <StyledNav />
    </header>
  );
};

export default MainHeader;
