import React, { ChangeEvent, FormEvent } from "react";
import Button from "../../UI/Button/Button";
import Card from "../../UI/Card/Card";
import { useEffect } from "react";
import { useReducer } from "react";
import { useContext } from "react";
import AuthContext from "../../store/auth-context";
import { StyledInput } from "../../UI/Input/Input.styles";
import { useRef } from "react";

interface Props {
  className?: string;
}

type ValidActionType =
  | { type: "email"; payload: string }
  | { type: "password"; payload: string };
const initialState = {
  emailValidate: false,
  passwordValidate: false,
  email: "",
  password: "",
};

const validityReducer = (
  state: typeof initialState,
  action: ValidActionType
) => {
  switch (action.type) {
    case "email":
      return {
        ...state,
        email: action.payload,
        emailValidate:
          state.email?.includes("@") && state.email?.trim().length > 6,
      };
    case "password":
      return {
        ...state,
        password: action.payload,
        passwordValidate: state?.password?.length > 7,
      };
    default:
      throw new Error("Wrong way!");
  }
};

const Login = ({ className }: Props) => {
  const [formState, dispatchForm] = useReducer(validityReducer, initialState);
  const { email, emailValidate, password, passwordValidate } = formState;
  const { onLogIn } = useContext(AuthContext)!;
  const emailRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const identify = setTimeout(() => {
      dispatchForm({ type: "email", payload: email });
      dispatchForm({ type: "password", payload: password });
    }, 500);

    return () => {
      clearTimeout(identify);
    };
  }, [email, password]);

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    if (!emailValidate) emailRef?.current?.focus();
    else if (!passwordValidate) passRef.current?.focus();
    else onLogIn({ email: email, password: password });
  };

  const enteredInfoChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatchForm({
      type: e.target.name === "email" ? "email" : "password",
      payload: e.target.value,
    });
  };

  return (
    <div className={className}>
      <Card className="login">
        <form onSubmit={submitHandler}>
          <div>
            <StyledInput
              ref={emailRef}
              id="email"
              name="email"
              type="email"
              value={email}
              className={emailValidate ? "" : "invalid"}
              onChange={enteredInfoChange}
              isValid={emailValidate}
            />
          </div>
          <div>
            <StyledInput
              ref={passRef}
              id="password"
              name="password"
              type="password"
              value={password}
              className={passwordValidate ? "" : "invalid"}
              onChange={enteredInfoChange}
              isValid={passwordValidate}
            />
          </div>
          <div className="actions"></div>
          <Button type={"submit"}>Login</Button>
        </form>
      </Card>
    </div>
  );
};

export default Login;
